<template>
  <div>
    <b-sidebar
      id="sidebar-backdrop"
      backdrop
      shadow
      bg-variant="white"
      sidebar-class="sidebar-lg"
      no-header
      right
      :visible="userAddSidebarActive"
      @change="(val) => $emit('update:user-add-sidebar-active', val)"
    >

      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Nuevo Usuario
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <!-- Campos formulario -->
        <validation-observer ref="simpleRules">
          <b-form
            v-if="show"
            class="p-2"
            @submit="onSubmit"
            @reset="onReset"
          >
            <!-- nombre -->
            <validation-provider
              v-slot="{ errors }"
              name="nombre"
              rules="required"
            >
              <b-form-group
                label="Nombre:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.name"
                  placeholder="Ingresa el nombre"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- direccion -->
            <validation-provider
              v-slot="{ errors }"
              name="direccion"
            >
              <b-form-group
                label="Dirección:"
                label-for="input-address"
              >
                <b-form-input
                  id="input-address"
                  v-model="form.address"
                  placeholder="Ingresa una dirección"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- telefono -->
            <validation-provider
              v-slot="{ errors }"
              name="telefono"
              rules="required"
            >
              <b-form-group
                label="Telefono:"
                label-for="input-cel"
              >
                <b-form-input
                  id="input-cel"
                  v-model="form.cel"
                  placeholder="Ingresa un número de telefono"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Rol -->
            <validation-provider
              v-slot="{ errors }"
              name="Rol"
              rules="required"
            >
              <b-form-group
                label="Rol:"
                label-for="select-rol"
              >
                <v-select
                  v-if="rols"
                  v-model="form.rol"
                  :options="rols"
                  :reduce="rol => rol.id"
                  :clearable="true"
                  input-id="select-rol"
                  required
                >
                  <template slot="no-options">
                    Lo siento, no se encontraron roles
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Region -->

            <b-form-group
              v-if="form.rol === 5"
              label="Región que cubre:"
              label-for="input-region"
            >
              <v-select
                v-model="form.region"
                :options="regions"
                :reduce="region => region.id"
                :clearable="true"
                input-id="select-region"
                required
              >
                <template slot="no-options">
                  Lo siento, no se encontraron regiones
                </template>
              </v-select>
            </b-form-group>

            <validation-provider
              v-slot="{ errors }"
              name="Contraseña"
              rules="required|password"
            >
              <!-- password -->
              <b-form-group>
                <label for="input-pass">Contraseña</label>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="input-pass"
                    v-model="form.password"
                    :type="password1FieldType"
                    placeholder="..........."
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Validar Contraseña -->
            <validation-provider
              v-slot="{ errors }"
              name="Confirmar contraseña"
              rules="required|password"
            >
              <b-form-group>
                <label for="input-confirmPass">Confirmar Contraseña</label>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="input-confirmPass"
                    v-model="form.validateP"
                    :type="password2FieldType"
                    placeholder="............."
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <b-button
              type="submit"
              variant="primary"
            >
              Crear
            </b-button>
            <b-button
              style="margin-left: 5px;"
              type="reset"
              variant="danger"
            >
              Limpiar
            </b-button>

          </b-form>
        </validation-observer>

      </template>

    </b-sidebar>
  </div>
</template>

<script>
import axios from '@axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@/utils/validations/validations'
import rolData from '../rols/rolData'
import userData from './usersData'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  model: {
    prop: 'userAddSidebarActive',
    event: 'update:user-add-sidebar-active',
  },
  props: {
    userAddSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      form: [
        {
          name: '',
          address: '',
          cel: '',
          rol: '',
          region: '',
          picture: '',
          password: '',
          validateP: '',
        },
      ],
      regionName: '',
      rolName: '',
      show: true,
      users: userData.users,
      rols: rolData.Roles,
      regions: rolData.Regiones,
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      /* eslint-disable global-require */
      img: require('@/assets/images/avatars/user.png'),
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    getRols() {
      axios
        .get('category?perPage=10&sortField=id&sortDesc=desc&filterField=status&filterValue=Y')
        .then(response => {
          response.data.data.forEach(element => {
            this.rols.push({
              label: element.name,
              id: element.id,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    onSubmit(event) {
      event.preventDefault()

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // axios
          // .post('user', this.form)
          // .then(response => {
          // this.makeToast('success', 'Usuario agregado', `Se ha creado el usuario ${response.data.data.name}.`)
          // this.clearForm()
          // this.$emit('update:user-add-sidebar-active', false)
          // this.$emit('refreshData')
          // })
          // .catch(error => {
          // this.showErrors(error)
          // })
          this.rols.forEach(element => {
            if (element.id === this.form.rol) {
              this.rolName = element.label
            }
          })
          this.regions.forEach(element => {
            if (element.id === this.form.region) {
              this.regionName = element.label
            }
          })
          this.users.push({
            id: this.users.length + 1,
            name: this.form.name,
            address: this.form.address,
            cel: this.form.cel,
            rolId: this.form.rol,
            rol: this.rolName,
            regionId: this.form.region,
            region: this.regionName,
            picture: this.form.picture,
            password: this.form.password,
            validateP: this.form.validateP,
          })
          this.makeToast('success', 'Usuario agregado', 'Se ha creado el producto con éxito.')
          this.$emit('update:user-add-sidebar-active', false)
          this.clearForm()
        } else {
          this.makeToast('danger', 'Error', 'No es posible crear el usuario.')
        }
      })
    },

    onReset(event) {
      event.preventDefault()
      this.clearForm()
    },

    clearForm() {
      // Reset our form values
      this.form.name = ''
      this.form.address = ''
      this.form.cel = ''
      this.form.region = ''
      this.form.picture = ''
      this.form.password = 0
      this.form.validateP = ''
      this.form.rol = ''

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },

    makeToast(variant = null, title = null, message = null) {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true,
      })
    },
  },
}
</script>
