var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-backdrop","backdrop":"","shadow":"","bg-variant":"white","sidebar-class":"sidebar-lg","no-header":"","right":"","visible":_vm.userEditSidebarActive},on:{"change":function (val) { return _vm.$emit('update:user-edit-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Editar Usuario ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"simpleRules"},[(_vm.show)?_c('b-form',{staticClass:"p-2",on:{"submit":_vm.onSubmit,"reset":_vm.clearForm}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nombre:","label-for":"input-name"}},[_c('b-form-input',{attrs:{"id":"input-name","placeholder":"Ingresa el nombre","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"direccion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Dirección:","label-for":"input-address"}},[_c('b-form-input',{attrs:{"id":"input-address","placeholder":"Ingresa una dirección"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"telefono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Telefono:","label-for":"input-cel"}},[_c('b-form-input',{attrs:{"id":"input-cel","placeholder":"Ingresa un número de telefono"},model:{value:(_vm.form.cel),callback:function ($$v) {_vm.$set(_vm.form, "cel", $$v)},expression:"form.cel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Rol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Rol:","label-for":"select-rol"}},[(_vm.rols)?_c('v-select',{attrs:{"options":_vm.rols,"reduce":function (rol) { return rol.id; },"clearable":true,"input-id":"select-rol","required":""},model:{value:(_vm.form.rolId),callback:function ($$v) {_vm.$set(_vm.form, "rolId", $$v)},expression:"form.rolId"}},[_c('template',{slot:"no-options"},[_vm._v(" Lo siento, no se encontraron roles ")])],2):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.form.rolId === 5)?_c('b-form-group',{attrs:{"label":"Región que cubre:","label-for":"input-region"}},[_c('v-select',{attrs:{"options":_vm.regions,"reduce":function (region) { return region.id; },"clearable":true,"input-id":"select-region","required":""},model:{value:(_vm.form.regionId),callback:function ($$v) {_vm.$set(_vm.form, "regionId", $$v)},expression:"form.regionId"}},[_c('template',{slot:"no-options"},[_vm._v(" Lo siento, no se encontraron regiones ")])],2)],1):_vm._e(),_c('validation-provider',{attrs:{"name":"Contraseña","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"input-pass"}},[_vm._v("Contraseña")]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"input-pass","type":_vm.password1FieldType,"placeholder":"..........."},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password1ToggleIcon},on:{"click":_vm.togglePassword1Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Confirmar contraseña","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"input-confirmPass"}},[_vm._v("Confirmar Contraseña")]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"input-confirmPass","type":_vm.password2FieldType,"placeholder":"............."},model:{value:(_vm.form.validateP),callback:function ($$v) {_vm.$set(_vm.form, "validateP", $$v)},expression:"form.validateP"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password2ToggleIcon},on:{"click":_vm.togglePassword2Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Actualizar ")])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }