export default {
  users: [
    {
      id: 1,
      name: 'Alberto Molina',
      address: 'Ciudad',
      cel: '78978542',
      rolId: 1,
      rol: 'Manager',
      regionId: null,
      region: '',
      /* eslint-disable global-require */
      picture: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
      password: 'holaMundo',
      validateP: 'holaMundo',
    },
    {
      id: 2,
      name: 'Julia Flores',
      address: 'SMP',
      cel: '7257213',
      rolId: 5,
      rol: 'Vendedor',
      regionId: 1,
      region: 'Villa Nueva',
      picture: '',
      password: 'holaMundo',
      validateP: 'holaMundo',
    },
    {
      id: 3,
      name: 'Julian Acevedo',
      address: 'Villa Nueva',
      cel: '74683312',
      rolId: 2,
      rol: 'Admin',
      regionId: null,
      region: '',
      picture: '',
      password: 'holaMundo',
      validateP: 'holaMundo',
    },
    {
      id: 4,
      name: 'Julio Cruz',
      address: 'Zona 16',
      cel: '54895123',
      rolId: 4,
      rol: 'Contador',
      regionId: null,
      region: '',
      picture: '',
      password: 'holaMundo',
      validateP: 'holaMundo',
    },
    {
      id: 5,
      name: 'Kenia Saravia',
      address: 'Zona 21',
      cel: '32548612',
      rolId: 2,
      rol: 'Admin',
      regionId: null,
      region: '',
      picture: '',
      password: 'holaMundo',
      validateP: 'holaMundo',
    },
  ],
}
