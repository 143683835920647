<template>
  <div>
    <b-sidebar
      id="sidebar-backdrop"
      backdrop
      shadow
      bg-variant="white"
      sidebar-class="sidebar-lg"
      no-header
      right
      :visible="userEditSidebarActive"
      @change="(val) => $emit('update:user-edit-sidebar-active', val)"
    >

      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Editar Usuario
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Campos formulario -->
        <validation-observer ref="simpleRules">
          <b-form
            v-if="show"
            class="p-2"
            @submit="onSubmit"
            @reset="clearForm"
          >
            <!-- nombre -->
            <validation-provider
              v-slot="{ errors }"
              name="nombre"
              rules="required"
            >
              <b-form-group
                label="Nombre:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.name"
                  placeholder="Ingresa el nombre"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- direccion -->
            <validation-provider
              v-slot="{ errors }"
              name="direccion"
            >
              <b-form-group
                label="Dirección:"
                label-for="input-address"
              >
                <b-form-input
                  id="input-address"
                  v-model="form.address"
                  placeholder="Ingresa una dirección"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- telefono -->
            <validation-provider
              v-slot="{ errors }"
              name="telefono"
              rules="required"
            >
              <b-form-group
                label="Telefono:"
                label-for="input-cel"
              >
                <b-form-input
                  id="input-cel"
                  v-model="form.cel"
                  placeholder="Ingresa un número de telefono"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Rol -->
            <validation-provider
              v-slot="{ errors }"
              name="Rol"
              rules="required"
            >
              <b-form-group
                label="Rol:"
                label-for="select-rol"
              >
                <v-select
                  v-if="rols"
                  v-model="form.rolId"
                  :options="rols"
                  :reduce="rol => rol.id"
                  :clearable="true"
                  input-id="select-rol"
                  required
                >
                  <template slot="no-options">
                    Lo siento, no se encontraron roles
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Region -->
            <b-form-group
              v-if="form.rolId === 5"
              label="Región que cubre:"
              label-for="input-region"
            >
              <v-select
                v-model="form.regionId"
                :options="regions"
                :reduce="region => region.id"
                :clearable="true"
                input-id="select-region"
                required
              >
                <template slot="no-options">
                  Lo siento, no se encontraron regiones
                </template>
              </v-select>
            </b-form-group>

            <!-- Contraseña -->
            <validation-provider
              v-slot="{ errors }"
              name="Contraseña"
              rules="required|password"
            >
              <!-- password -->
              <b-form-group>
                <label for="input-pass">Contraseña</label>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="input-pass"
                    v-model="form.password"
                    :type="password1FieldType"
                    placeholder="..........."
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Validar Contraseña -->
            <validation-provider
              v-slot="{ errors }"
              name="Confirmar contraseña"
              rules="required|password"
            >
              <b-form-group>
                <label for="input-confirmPass">Confirmar Contraseña</label>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="input-confirmPass"
                    v-model="form.validateP"
                    :type="password2FieldType"
                    placeholder="............."
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <b-button
              type="submit"
              variant="primary"
            >
              Actualizar
            </b-button>

          </b-form>
        </validation-observer>

      </template>

    </b-sidebar>
  </div>
</template>

<script>
// import axios from '@axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@/utils/validations/validations'
import rolData from '../rols/rolData'
import userData from './usersData'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  model: {
    prop: 'userEditSidebarActive',
    event: 'update:user-edit-sidebar-active',
  },
  props: {
    userEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    userInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      form: {
        name: '',
        address: '',
        cel: '',
        rolId: 0,
        rol: '',
        regionId: 0,
        region: '',
        picture: '',
        password: '',
        validateP: '',
      },
      show: true,
      users: userData.users,
      rols: rolData.Roles,
      regions: rolData.Regiones,
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  watch: {
    userEditSidebarActive(val) {
      if (val) {
        this.getUser()
      }
    },
  },
  created() {
    this.getUser()
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    userData() {
      return JSON.parse(localStorage.getItem('KultiverAuthUser'))
    },

    getUser() {
      // axios
      //  .get(`user/${this.userId}`)
      //  .then(response => {
      //    this.form.name = response.data.data.name
      //    this.form.description = response.data.data.description
      //    this.form.unit_id = response.data.data.unit_id
      //    this.form.category_id = response.data.data.category_id
      //    this.form.stock = response.data.data.stock
      //    this.form.stock_by_store = response.data.data.stock_by_store
      //    this.form.price = response.data.data.price
      //    this.form.cost = response.data.data.cost
      //    this.form.status = response.data.data.status
      //  })
      //  .catch(error => {
      //    this.showErrors(error)
      //  })
      this.users.forEach(element => {
        if (element.id === this.userId) {
          this.form.name = element.name
          this.form.address = element.address
          this.form.cel = element.cel
          this.form.rolId = element.rolId
          this.form.rol = element.rol
          this.form.regionId = element.regionId
          this.form.regions = element.region
          this.form.picture = element.picture
          this.form.password = element.password
          this.form.validateP = element.validateP
        }
      })
    },

    onSubmit(event) {
      event.preventDefault()

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          for (let index = 0; index < this.users.length; index += 1) {
            const element = this.users[index]
            if (element.id === this.userId) {
              element.name = this.form.name
              element.address = this.form.address
              element.cel = this.form.cel
              element.rolId = this.form.rolId
              element.rol = this.form.rol
              element.regionId = this.form.regionId
              element.region = this.form.region
              element.picture = this.form.picture
              element.password = this.form.password
              element.validateP = this.form.validateP
            }
          }
          this.makeToast('success', 'Usuario actualizado', 'Se actualizó el usuario con éxito')
          this.$emit('update:user-edit-sidebar-active', false)
          this.clearForm()
        } else {
          this.makeToast('danger', 'Error', 'No es posible actualizar el usuario.')
        }
      })
    },

    clearForm() {
      // Reset our form values
      this.form.name = ''
      this.form.description = ''

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },

    makeToast(variant = null, title = null, message = null) {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true,
      })
    },
  },
}
</script>
